/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react';
import { CoreFeatureSection, Divider, CoreWrapper, WrapperContainer, IntroTextContainer, RowWrapper, QuestionContainer, TabData, Button2, CoreWrapperr, MainContent, Section, CoreWrapperrNew, /*Test,*/ TestNew } from './style';
import { ImageUrl, imgPath } from '../../utils/constants';
import PropTypes from "prop-types";
import data from './wellnessResources.json'
import { connect } from 'react-redux';
import { subscribeNewsLetter } from '../../redux/actions/authActions';
import testimonialData from './TestimonialData.json'
import coreData from './Challenges.json'
import coreDataMore from './ChallengesMore.json'
import { ButtonGetStarted, WellBeing, GlobalMainWrapper } from '../MainDashboardV3/styles';
import { MainResources, RowResponsiveWrapper } from '../WellnessPageV2/styles'
import { ResourcesWrapper, Resources } from '../DashboardRecipes/style';
import QuestionTile from './QuestionTile';
import TeamPackage from './TeamPackage';
import coreDataLess from './ChallengesLess.json';
import coreDataNew from './ChallengesNew.json';
import BilledPackage from './BilledPackage';
import LazyImage from '../common/LazyImage/LazyImage';

const questionList = [
  {
    question: 'Can we pay monthly or annually?',
    answer: 'Both, if you pay annually you will get 2 months FREE.'
  },
  {
    question: 'Do you have software agreements?',
    answer: 'Yes, we can do 1, 2, or 3 year agreements.'
  },
  {
    question: 'Do you offer a discount for buying the entire Woliba platform and software add-ons?',
    answer: "Yes, we provide a 20% discount if you purchase all the add-ons."
  },
  {
    question: 'Do you offer volume discounts if we have over 500 employees?',
    answer: 'Yes, please contact our sales team for a more detailed quote.'
  },
  {
    question: 'Can we include add-ons after we launched our program?',
    answer: 'Yes'
  }
];

class PricingPage extends Component {
  constructor() {
    super();
    this.state = {
      mobileViewStatus: window.innerWidth <= 500,
      active: '',
      more: 0,
      less: 0,
      selectedImage: '',
      selectedText: 'WHY USERS LOVE US',
      selectedTab: 0,
      wellnessResources: JSON.parse(JSON.stringify(data)),
      image: [data[0].data[0].image],
      email: '',
      tabArray: ["Insurance", "Banking", "Technology", "Healthcare", "Civil Engineering"],
      ratingImage: [testimonialData[0].imageURL, testimonialData[1].imageURL, testimonialData[2].imageURL, testimonialData[3].imageURL, testimonialData[4].imageURL],
      ratingData: [testimonialData[0].data, testimonialData[1].data, testimonialData[2].data, testimonialData[3].data, testimonialData[4].data],
      ratingName: [testimonialData[0].head, testimonialData[1].head, testimonialData[2].head, testimonialData[3].head, testimonialData[4].head],
      ratingMain: [testimonialData[0].main, testimonialData[1].main, testimonialData[2].main, testimonialData[3].main, testimonialData[4].main],
      selectedChallengeData: coreData[0].data,
      selectedChallengeDataMore: coreDataMore[0].data,
      selectedChallengeDataLess: coreDataLess[0].data,
      selectedChallengeDataNew: coreDataNew[0].data,
      tempNew: 0,
      current: 0,
    }
  }

  componentDidMount() {
    this.setState({ active: 'Insurance' })
  }

  TopPanelSection = () => (
    <MainContent>
      <Section firstChild margin="0">
        <IntroTextContainer>
          <p>
            {"We Don't Take A One Size Fits All Approach"} {this.state.mobileViewStatus ? '' : <br />}
          </p>
          <h1>
            Discover the plan that<br />best supports your teams goals
          </h1>
          <BilledPackage history={this.props.history}/>
        </IntroTextContainer>
      </Section>      
    </MainContent>
  );

  decreaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab - 1;
    this.setState({ selectedTab: temp })
  }

  increaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab + 1;
    this.setState({ selectedTab: temp })
  }

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  }

  handleResources = (resIndex, rowIndex) => {
    const { wellnessResources, image } = this.state;
    let array = wellnessResources;
    wellnessResources[resIndex].data.forEach((info, index) => {
      array[resIndex].data[index].status = info.status === 1 && 0;
    })
    array[resIndex].data[rowIndex].status = 1;
    let images = image;
    images[resIndex] = wellnessResources[resIndex].data[rowIndex].image;
    this.setState({ wellnessResources: array, image: images });
  }

  multipleFeatures = () => (
    <ResourcesWrapper>{
      this.state.wellnessResources.length > 0 && this.state.wellnessResources.map((row, index) =>
        (<MainResources key={index} color={row.color}>
          <Resources flexDirection={row.swapRow} col={row.color}>
            <div>
              <div />
              <div className="normal">
                {
                  row.data.map((info, rowindex) => (
                    <RowWrapper key={rowindex} selected={info.status}
                      onClick={() => this.handleResources(index, rowindex)}
                      customColor={info.status === 1 ? 1 : 0}>
                      <div className={"header"}>
                        <div>{info.header}</div>
                        <LazyImage div={"downArrow"} onClick={() => { info.status = 0 }} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                      </div>
                      {(info.status === 1) && <div className={"expand"}>
                        {info.description}
                      </div>}
                    </RowWrapper>
                  ))
                }
              </div>
              <div className="responsive">
                {
                  row.data.map((info, rowindex) => (
                    <div>
                      <RowResponsiveWrapper key={rowindex} selected={info.status} onClick={() => this.handleResources(index, rowindex)} customColor={info.status === 1 ? 1 : 0}>
                        <div className={"header"}>
                          <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                          <div>{info.header}</div>
                          <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                        </div>
                        {(info.status === 1) && <div className={"expand"}>
                          {info.description}
                        </div>}
                      </RowResponsiveWrapper>
                      {(info.status === 1) && <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.image[index]}/>}
                    </div>
                  ))
                }
              </div>
            </div>
          </Resources>
        </MainResources>))
    }</ResourcesWrapper>);

  coreFeatureSection = () => (
    <CoreFeatureSection>
      <div id='faq'>
        <h2>FREQUENTLY ASKED QUESTIONS</h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </div>
    </CoreFeatureSection>
  );

  wellBeingSection = () => (
    <WellBeing>
      < div>
        <div>
          <p>Are you ready to <br />
            create a culture of <br />
            wellbeing?</p>
          <ButtonGetStarted display={'block'} onClick={() => this.redirection()}>GET STARTED</ButtonGetStarted>
        </div>
      </div>
      <LazyImage src={ImageUrl + "/images/HomePageV2/footerImage.png"}/>
    </WellBeing>
  )

  onEmailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  divider = () => (
    <Divider>
      <img src={`${imgPath}/WellnessPage/blueDivider.png`} alt={"breaker"} />
    </Divider>
  )

  onSubmit = (e) => {
    e.preventDefault();
    const { subscribeNewsLetter, history } = this.props;
    const { email } = this.state;
    let payload = {
      email
    };
    if (email.trim() !== '') {
      subscribeNewsLetter(history, payload);
      this.setState({
        email: ''
      })
    }
  };

  globalPositionSection = () => (
    <GlobalMainWrapper>
      <div>
        <h1>We Have Been Consistently Ranked As A Leader</h1>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </div>
      <div>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/container1.png"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/container2.png"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/container3.png"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/container4.png"} width="70%"/>
        <LazyImage src={ImageUrl + "/images/NewHomePageV2/container5.png"} width="70%"/>
      </div>
    </GlobalMainWrapper>
  )

  readMore = () => {
    this.setState({ more: !this.state.more });
    this.setState({ less: !this.state.less });
  }

  handleTable = () => {
    this.setState({ tempNew: !this.state.tempNew });
    this.setState({ current: !this.state.current });
  }

  handleTableNew = () => {
    let temp = 0;
    this.setState({ tempNew: temp });
  }

  renderCards = () => (
    <CoreWrapper>
      <div />
      <MainContent>
        <div className="card-button">
          <button onClick={() => this.readMore()}>
            {this.state.less ?
              (<button className="hide-button">
                <div className='hide-button-text'>Hide Comparison</div>
                <LazyImage src={ImageUrl + "/images/PricingPageImages/downarrow.png"} />
              </button>)
              :
              (<button className="show-button">
                <div className='show-button-text'>Compare Plans</div>
                <LazyImage src={ImageUrl + "/images/PricingPageImages/rightarrow.png"} /></button>)
            }
          </button>
        </div>
      </MainContent>
      {this.state.more ? <TeamPackage /> : <span />}
      <div className='enterprise-card-wrapper'>
        <div className='enterprise-card'>
          <LazyImage div={"enterprise-pic"} className='enter-img' src={ImageUrl +"/images/enterpriseplan.svg"}/>
          <div className='enterprise-content'>
            <div className='card-content'>
              <div className='upper-text'>Enterprise Plan For More than
                500+ Employees Organization</div>
              <div className='lower-text'>Custom-priced, scalable enterprise software for
                your growing, multi-location business.</div>
            </div>
            <div className='card-btns'>
              <div className='sales-btn'>
                <button className='contact-button' onClick={() => this.redirection()}>Contact Sales</button>
              </div>
              <div className='custom-text'>
                For Custom Pricing
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='enterprise-card-wrapper-mob'>
        <div className='enterprise-card-mob'>
          <div className='enterprise-content-mob'>
            <div className='card-content-mob'>
              <div className='upper-text-mob'>Enterprise Plan For More than
                500+ Employees Organization</div>
              <LazyImage div={"enterprise-pic-mob"} className='enter-img-mob' src={ImageUrl +"/images/enterpriseplan.svg"}/>
              <div className='lower-text-mob'>Custom-priced, scalable enterprise software for
                your growing, multi-location business.</div>
              <div className='card-btns-mob'>
                <div className='sales-btn-mob'>
                  <button className='contact-button-mob' onClick={() => this.redirection()}>Contact Sales</button>
                </div>
                <div className='custom-text-mob'>
                  For Custom Pricing
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CoreWrapper>
  )

  renderOnDemandCards = () => (
    <CoreWrapperr >
      <div>
        <div className="tab-header">
          <h2>{"Add-Ons To Customize your Program"}</h2>
          <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
        </div>
      </div>
    </CoreWrapperr>
  )

  renderOnDemandCardsNew = () => (
    <CoreWrapperrNew>
      <div className="align">
        {this.state.selectedChallengeDataLess.map((row, index) => (
          <TestNew bgColor={row.bgcolor}>
            <div className="card-container">
              <div className="card-data" key={index} >
                <div className="first-div" bgColor={"red"}>
                  <LazyImage src={`${ImageUrl}${row.img}`} />
                  <h3>{row.head}</h3>
                </div>
                <div className="second-div">
                  <p>{row.desc}</p>
                </div>
                <div className="third-div">
                  <div>
                    <LazyImage src={`${ImageUrl}${row.img1}`} />
                    <p>{row.desc1}</p>
                  </div>
                  <div>
                    <LazyImage src={`${ImageUrl}${row.img2}`} />
                    <p>{row.desc2}</p>
                  </div>
                  <div>
                    <LazyImage src={`${ImageUrl}${row.img3}`} />
                    <p>{row.desc3}</p>
                  </div>
                </div>
                <div className="four-div">
                  <div>
                    <p>{row.rupee}</p>
                    <span>{row.amount}</span>
                  </div>
                  <div>
                    <button onClick={() => this.redirection()}>
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TestNew>
        ))}
      </div>
    </CoreWrapperrNew>
  )

  renderOnDemandCardsLast = () => (
    <CoreWrapperrNew style={{ "margin-bottom": "75px" }}>
      <div className="align">
        {this.state.selectedChallengeDataNew.map((row, index) => (
          <TestNew bgColor={row.bgcolor}>
            <div className="card-container" bgColor={"red"}>
              <div className="card-data" key={index} >
                <div className="first-div" bgColor={"red"}>
                  <LazyImage src={`${ImageUrl}${row.img}`} />
                  <h3>{row.head}</h3>
                </div>
                <div className="second-div">
                  <h3>{row.desc}</h3>
                </div>
                <div className="third-div">
                  <div>
                    <LazyImage src={`${ImageUrl}${row.img1}`} />
                    <p>{row.desc1}</p>
                  </div>
                  <div>
                    <LazyImage src={`${ImageUrl}${row.img2}`} />
                    <p>{row.desc2}</p>
                  </div>
                  <div>
                    <LazyImage src={`${ImageUrl}${row.img3}`} />
                    <p>{row.desc3}</p>
                  </div>
                </div>
                <div className="four-div">
                  <div>
                    <p>{row.rupee}</p>
                    {row.text && (<h2>{row.text}</h2>)}
                    <span>{row.amount}</span>
                  </div>
                  <div>
                    <button onClick={() => this.redirection()}>
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </TestNew>
        ))}
      </div>
    </CoreWrapperrNew>
  )

  renderQuestions = () => (
    <QuestionContainer>
      <div />
      <div className="questionContainer">
        {
          questionList.map((item, index) => (
            <QuestionTile key={index} item={item} index={index} />
          ))
        }
      </div>
    </QuestionContainer>
  )

  renderTabData = () => (
    <TabData>
      <div className="responsiveWhyButton">
        <Button2 display={'block'} onClick={() => this.redirection()}>BOOK A DEMO</Button2>
      </div>
      <LazyImage src={ImageUrl + '/images/PricingPageImages/WolibaForGood.svg'} alt={"WolibaForGood"}/>
      <div>
        <h2>Woliba For Good </h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/blueDivider.png"} />
        <p>Woliba wants to support organizations and{this.state.mobileViewStatus ? '' : <br />} give back by providing special pricing for {this.state.mobileViewStatus ? '' : <br />}
          qualified nonprofit organizations and {this.state.mobileViewStatus ? '' : <br />}educational institutions.</p>
        <Button2 display={'block'} onClick={() => this.redirection()}>REQUEST A DEMO</Button2>
      </div>
    </TabData>
  )

  redirection = () => {
    const { history } = this.props;
    history.push('/contact');
  }

  render() {
    return (
      <WrapperContainer>
        {this.TopPanelSection()}
        {this.renderCards()}
        {this.renderOnDemandCards()}
        {this.renderOnDemandCardsNew()}
        {this.renderOnDemandCardsLast()}
        {this.renderTabData()}
        {this.coreFeatureSection()}
        {this.renderQuestions()}
        {this.wellBeingSection()}
      </WrapperContainer>
    );
  }
}

PricingPage.propTypes = {
  history: PropTypes.object,
  subscribeNewsLetter: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  subscribeNewsLetter: (history, payload) => dispatch(subscribeNewsLetter(history, payload))
});

export default connect(null, mapDispatchToProps)(PricingPage);